import { defineAsyncComponent } from 'vue';
import { mountApp, app } from './global';

const MainHeader = defineAsyncComponent(() => import('../../global/header/MainHeader.vue'));
const PrizePoolModal = defineAsyncComponent(() => import('../../home/PrizePoolModal.vue'));
const MediaPartnersModal = defineAsyncComponent(() => import('../../home/MediaPartnersModal.vue'));

app.component('PrizePoolModal', PrizePoolModal);
app.component('MediaPartnersModal', MediaPartnersModal);
app.component('MainHeader', MainHeader);

mountApp();
